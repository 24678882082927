import { getExecute } from '@/utils/dialogExecute'

export const rules = {
  name: [
    { required: true, message: '请输入园区名称', trigger: 'change' }
  ],
  location: [
    { required: true, message: '请输入园区地址', trigger: 'change' }
  ],
  mobile: [
    { required: true, message: '请输入园区联系方式', trigger: 'change' }
  ],
  cover: [
    { required: true, message: '请上传园区封面', trigger: 'change' }
  ],
  description: [
    { required: true, message: '请输入园区介绍', trigger: 'change' }
  ]
}

export const newsRules = {
  title: [
    { required: true, message: '请输入园区动态名称', trigger: 'change' }
  ],
  cover: [
    { required: true, message: '请上传园区动态封面', trigger: 'change' }
  ],
  publisher: [
    { required: true, message: '请上传园区动态发布者', trigger: 'change' }
  ]
}

export function resetPark() {
  return {
    name: '',
    location: '',
    mobile: '',
    cover: '',
    description: ''
  }
}
export function resetParkNews() {
  return {
    title: '',
    publisher: '',
    cover: '',
    content: '',
    url: ''
  }
}

const singleVue = {
  addOrEditPark: false,
  addOrEditParkNews: false,
  showImg: false
}

const strategyMap = {
  addOrEditPark: () => import('./components/addOrEditPark.vue'), // 新增园区
  addOrEditParkNews: () => import('./components/addOrEditParkNews.vue'), // 新增园区资讯
  showImg: () => import('./components/showImg.vue') // 日志显示
}

export const execute = getExecute(singleVue, strategyMap)
